import { Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogClose } from '@angular/material/dialog';
import { ButtonComponent } from '../button/button.component';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'cramo-dialog',
	standalone: true,
	imports: [ButtonComponent, MatButtonModule, IconComponent, MatDialogClose],
	templateUrl: './dialog.component.html',
	styleUrl: './dialog.component.scss',
})
export class DialogComponent {
	public hideClose = input(false);

	public title = input<string>('');
}
