<div class="title-bar">
	<div class="title">{{ title() }}</div>

	@if (!hideClose) {
		<button mat-button class="close-button" mat-dialog-close>
			<cramo-icon iconName="close" [size]="20" />
		</button>
	}
</div>

<ng-content />
